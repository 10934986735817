import React, { useState } from "react";

const CrmViewLead = () => {
  const [formData, setFormData] = useState({
    searchTag: "",
    fromDate: "",
    toDate: "",
    nextFromDate: "",
    nextToDate: "",
    level_of_interest: "",
    country: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <div className="row crm-view-lead">
      <div className="col-xl-12">
        <div className="frm-content">
          <div className="crm_panel__h6izZ undefined">
            <div className="p-3">
              <legend>
                <span>Find Lead</span>
              </legend>
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-xl-4">
                      <div className="form-group">
                        <label className="form-label">{"Search Tag"}</label>
                        <input
                          name={"Search Tag"}
                          placeholder={
                            "Search by first name, last name, skype ID, email ID, mobile no."
                          }
                          type="text"
                          className="form-control"
                          value={formData.searchTag}
                          onChange={handleInputChange}
                        />
                        <small className="d-none form-text">&nbsp;</small>
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          {"Lead Added From Date"}
                        </label>
                        <input
                          name={"fromDate"}
                          placeholder={"From Date"}
                          type="text"
                          className="form-control"
                          value={formData.fromDate}
                          onChange={handleInputChange}
                        />
                        <small className="d-none form-text">&nbsp;</small>
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          {"Lead Added To Date"}
                        </label>
                        <input
                          name={"toDate"}
                          placeholder={"To Date"}
                          type="text"
                          className="form-control"
                          value={formData.toDate}
                          onChange={handleInputChange}
                        />
                        <small className="d-none form-text">&nbsp;</small>
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          {"Lead Added To Date"}
                        </label>
                        <input
                          name={"nextFromDate"}
                          placeholder={"From Date"}
                          type="text"
                          className="form-control"
                          value={formData.nextFromDate}
                          onChange={handleInputChange}
                        />
                        <small className="d-none form-text">&nbsp;</small>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="form-group">
                        <label className="form-label">
                          {"Next Follow-up To Date"}
                        </label>
                        <input
                          name={"nextToDate"}
                          placeholder={"To Date"}
                          type="text"
                          className="form-control"
                          value={formData.nextToDate}
                          onChange={handleInputChange}
                        />
                        <small className="d-none form-text">&nbsp;</small>
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          {"Level Of Interest"}
                        </label>
                        <select
                          name="interest_status"
                          class="form-control"
                          value={formData.level_of_interest}
                        >
                          <option value="All">All</option>
                          <option value="Very Interested">
                            Very Interested
                          </option>
                          <option value="Interested">Interested</option>
                          <option value="Not That Interested">
                            Not That Interested
                          </option>
                        </select>
                        <small className="d-none form-text">&nbsp;</small>
                      </div>
                      <div className="form-group">
                        <label className="form-label">{"Country"}</label>
                        <select
                          name="interest_status"
                          class="form-control"
                          value={formData.country}
                        >
                          <option value="Country">Country</option>
                        </select>
                        <small className="d-none form-text">&nbsp;</small>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div class="form-group">
                        <label class="form-label">Lead Status</label>
                        <select name="lead_status" className="form-control">
                          <option value="All">All</option>
                          <option value="Ongoing">Ongoing</option>
                          <option value="Accepted">Accepted</option>
                          <option value="Rejected">Rejected</option>
                        </select>
                        <small
                          class="
                d-none
                 form-text"
                        >
                          &nbsp;
                        </small>
                      </div>
                      <div class="form-group">
                        <label class="form-label">
                          Lead Status Change From Date
                        </label>
                        <div>
                          <div>
                            <input
                              class="form-control"
                              placeholder="From Date"
                              type="text"
                              value=""
                            />
                          </div>
                        </div>
                        <small class="d-none form-text">&nbsp;</small>
                      </div>
                      <div class="form-group">
                        <label class="form-label">
                          Lead Status Change To Date
                        </label>
                        <div>
                          <div>
                            <input
                              class="form-control"
                              placeholder="To Date"
                              type="text"
                              value=""
                            />
                          </div>
                        </div>
                        <small class="d-none form-text">&nbsp;</small>
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-12">
        <div className="table-sec">
          <div className="crm_panel__h6izZ undefined">
            <div className="crm_panel_body__31wq1 p-0">
              <div className="crm_table_responsive__cut_1 crm_panel__h6izZ undefined">
                <table className="table table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Full Name</th>
                      <th>Lead Completeness</th>
                      <th>Email</th>
                      <th>Edit Lead</th>
                      <th>View Lead</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="11" align="center">
                        No Details Found
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrmViewLead;
