import React from "react";
import YearlyChart from "./YearChart";
import MonthlyChart from "./MonthChart";

const CrmGraphSegment = () => {


  return (
    <>
      <div className="page_head_top">GRAPH</div>

      <div className="row">
        <YearlyChart />
        <MonthlyChart />
      </div>
    </>
  );
};

export default CrmGraphSegment;
