import React, { useState } from "react";
import PayoutTiles from "../../components/Payout/PayoutTiles";
import PayoutTable from "../../components/Payout/PayoutTable";
import SubmitButton from "../../components/Common/buttons/SubmitButton";
import PayoutRequest from "../../components/Payout/PayoutRequest";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { Popover, OverlayTrigger } from "react-bootstrap";

const PayoutLayout = () => {
  const { t } = useTranslation();
  const [showPayout, setShowPayout] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState([
    // { value: "paid", label: `${t("paid")}` },
    // { value: "requested", label: `${t("requested")}` },
    // { value: "approved", label: `${t("approved")}` },
    // { value: "rejected", label: `${t("rejected")}` },
    { value: "cash", label: `${t("cash")}` },
    { value: "product", label: `${t("product")}` },
  ]);
  const activeTabValues = activeTab.map((item) => item.value);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handlePayout = () => {
    setShowPayout((prevShowPayout) => !prevShowPayout);
  };

  //--------------------------------- API --------------------------------
  const payoutDetails = ApiHook.CallPayoutDetails(
    currentPage,
    itemsPerPage,
    activeTabValues.map((item) => `"${item}"`)
  );
  const requestDetails = ApiHook.CallPayoutRequestDetails();
  const tiles = ApiHook.CallPayoutTiles();
  // const popoverLeft = (
  //   <Popover
  //     id="popover-positioned-bottom"
  //     title="Popover left"
  //     style={{ maxWidth: "300px" }}
  //   >
  //     <div className="payout_req_popover">
  //       <div className="payout_req_popover_content">
  //         {requestDetails?.data?.errorMsg.map((item) => {
  //           return <div>{`* ${item}`}</div>;
  //         })}
  //       </div>
  //     </div>
  //   </Popover>
  // );
  return (
    <>
      <div className="page_head_top">{t("payout")}</div>
      <div className="ewallet_top_btn_sec">
        <div className="row justify-content-between">
          <div className="col-md-4"></div>
          <div className="col-md-4 text-end ">
            <div className="dropdown btn-group top_right_pop_btn_position">
              {/* {!requestDetails?.data?.isEligible ? (
                <>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    // trigger="click"
                    placement="bottom"
                    overlay={popoverLeft}
                  >
                    <button
                      className={"top_righ_pop_btn"}
                      type="submit"
                      disabled={true}
                    >
                      <i
                        class="fa-solid fa-lock"
                        style={{ paddingRight: "10px" }}
                      />
                      {t("payoutRequest")}
                    </button>
                  </OverlayTrigger>
                </>
              ) : ( */}
                <SubmitButton
                  isSubmitting=""
                  click={handlePayout}
                  text={"payoutRequest"}
                  className="top_righ_pop_btn"
                />
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <PayoutTiles
        percentage={tiles?.data?.tilePercentages}
        tiles={tiles?.data?.newTiles}
      />
      <PayoutTable
        data={payoutDetails?.data?.payoutDetails}
        type={"payout"}
        setActiveTab={setActiveTab}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        activeTab={activeTab}
      />
      <PayoutRequest
        show={showPayout}
        handleClose={handlePayout}
        data={requestDetails?.data?.data}
        payoutTypes={requestDetails?.data?.payoutTypes}
      />
    </>
  );
};

export default PayoutLayout;
