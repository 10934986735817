import React from "react";
import CrmLinks from "../../components/Crm/CrmLinks";
import CrmTilesSegment from "../../components/Crm/CrmTilesSegment";

const CrmDashboard = () => {
  return (
    <>
      <div className="page_head_top">
        {"CRM"}
        <div className="right_btn_mob_toggle">
          <i className="fa fa-bars"></i>
        </div>
      </div>
      <CrmLinks />
      <CrmTilesSegment />
    </>
  );
};

export default CrmDashboard;
