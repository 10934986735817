import { ReportService } from "../../services/report/report";
export const OrderReport = async (data) => {
  try {
    const response = await ReportService.getOrderReport(data);
    return response;
  } catch (error) {
    return error.message;
  }
};
export const CouponReport = async (data) => {
  try {
    const response = await ReportService.getCouponReport(data);
    return response;
  } catch (error) {
    return error.message;
  }
};
