import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const YearlyChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const config = {
      type: "line",
      data: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Accepted",
            backgroundColor: "#954cea",
            borderColor: "#954cea",
            fill: false,
            data: [10, 20, 30, 40, 100, 50, 150, 40, 100, 50, 150, 50],
          },
          {
            label: "Rejected",
            backgroundColor: "#2c008a",
            borderColor: "#2c008a",
            fill: false,
            data: [50, 300, 100, 450, 150, 200, 300, 100, 450, 150, 200, 300],
          },
          {
            label: "Ongoing",
            backgroundColor: "#9b88f9",
            borderColor: "#9b88f9",
            fill: false,
            data: [70, 250, 200, 350, 120, 100, 200, 200, 350, 120, 100, 200],
          },
        ],
      },
      options: {
        responsive: true,
        animations: {
            tension: {
                duration: 2000,
                easing: 'linear',
                from: 0.1,
                to: 0.45,
                loop: true
            }
        },
        plugins: {
          title: {
            display: true,
            text: "Leads this year",
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Month",
            },
          },
          y: {
            title: {
              display: true,
            },
            min: 0,
            max: 500,
            ticks: {
              stepSize: 100,
            },
          },
        },
      },
    };

    const ctx = document.getElementById("lead1");

    if (chartRef.current) {
      chartRef.current.destroy(); // Destroy existing chart instance
    }
    if (ctx) {
      chartRef.current = new Chart(ctx, config); // Create new chart
    }
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy(); // Cleanup on unmount
      }
    };
  }, []);

  return (
    <div className="col-xl-6 col-lg-12">
      <div className="grph-layout">
        <canvas id="lead1" />
      </div>
    </div>
  );
};

export default YearlyChart;
