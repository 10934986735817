import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { ApiHook } from "../../hooks/apiHook";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import _ from "lodash";

export default function NewsPopup({ data }) {
  const queryClient = useQueryClient();
  const submit = ApiHook.CallUpdateNewsRead();

  const [show, setShow] = useState(true);
  const [newsIndex, setNewsIndex] = useState(0);

  const handleShow = (value) => setShow(value);
  const handleNext = () => {
    if (newsIndex < data.length - 1) {
      setNewsIndex(newsIndex + 1);
    } else {
      handleShow(false);
      submitHandler();
    }
  };
  const submitHandler = async () => {
    const newsIds = _.map(data, "id");
    const response = await submit.mutateAsync({ newsIds });
    if (response) {
      toast.success("Everything up to date");
      queryClient.refetchQueries("dashboard-details");
    }
  };
  const handlePrev = () => {
    if (newsIndex > 0) {
      setNewsIndex(newsIndex - 1);
    }
  };

  return (
    <>
      <div>
        {" "}
        {show && (
          <Modal
            show={show}
            onHide={() => handleShow(false)}
            backdrop="static"
            centered
            keyboard={false}
            size="lg"
          >
            <Modal.Header style={{ justifyContent: "center" }}>
              <Modal.Title>News</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                height: "400px",
                overflowY: "auto",
                overflowX: "hidden",
                alignItems: "left",
              }}
            >
              {
                <div>
                  <h4>{data[newsIndex].title}</h4>
                  {data[newsIndex].image && (
                    <>
                    <br />
                      <div>
                        <img src={data[newsIndex].image} alt="image" style={{maxWidth:"380px"}}/>
                      </div>
                      <br />
                    </>
                  )}
                  <div style={{ whiteSpace: "pre-line" }}>
                    {data[newsIndex].description}
                  </div>
                </div>
              }
            </Modal.Body>
            <Modal.Footer>
              {newsIndex > 0 && (
                <Button variant="secondary" onClick={handlePrev}>
                  {"prev"}
                </Button>
              )}
              <Button
                variant={
                  Number(newsIndex) === data.length - 1 ? "success" : "primary"
                }
                onClick={handleNext}
              >
                {Number(newsIndex) === data.length - 1 ? "finish" : "next"}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </>
  );
}
