import React, { useState } from "react";
import TableMain from "../../components/Common/table/TableMain";
import { getLastPage } from "../../utils/getLastPage";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";

export default function CouponReport() {
  const headers = [
    "No.",
    "Coupon Code",
    "Amount",
    // "Usage Limit",
    "Type",
    "Expiry Date",
  ];

  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const couponReport = ApiHook.CallCouponReport(
    currentPage,
    itemsPerPage,
  );
  const lastPage = getLastPage(
    itemsPerPage,
    couponReport?.data?.data?.data?.totalCount
  );
  return (
    <>
      {" "}
      <div className="page_head_top">{t("couponReport")}</div>
      <div className="ewallet_table_section">
        <div className="ewallet_table_section_cnt">
          <div className="table-responsive min-hieght-table">
            <TableMain
              headers={headers}
              data={couponReport?.data?.data?.data?.tableData}
              startPage={1}
              currentPage={currentPage}
              totalPages={lastPage}
              type={"couponReport"}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
