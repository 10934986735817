import React from "react";
import CrmTiles from "./crmTiles";

const CrmTilesSegment = () => {

  return (
    <div className="crm-con-sec">
      <div className="row">
        <CrmTiles text={"total_ongoing_leads"} count={0} />
        <CrmTiles text={"total_accepted_leads"} count={0} />
        <CrmTiles text={"total_rejected_leads"} count={0} />
      </div>
    </div>
  );
};

export default CrmTilesSegment;
